<script>
import Card from '@/components/common/Card.vue'
import {getApi2} from '@/api'

export default {
	_config: {"route": {"path": "look", "meta": {"title": "查看"}}},
	components: {Card},
	data() {
		return {
			info: {},
			list1: [],
			image: [],
		}
	},
	created() {
		this.getData()
	},
	methods: {
		getData() {
			getApi2('/feedback/index/show', {id: this.$route.query.id}).then(res => {
				const list1 = [
					{label: '反馈人', content: res.name, span: 8},
					{label: '用户类型', content: res.role, span: 16},
					{label: res.role_identifying === 1 ? '学号' : '账号', content: res.username, span: 8},
				]
				if (res.role_identifying === 2) {
					list1.push({label: '人员部门', content: res.department, span: 16})
					list1.push({label: '教学学科', content: res.subject, span: 8})
				} else {
					list1.push({label: '行政班', content: res.class_name, span: 16})
					list1.push({label: '班主任', content: res.teacher_name, span: 8})
				}
				list1.push({label: '校区', content: res.school, span: 16})
				this.info = res
				this.list1 = list1
				this.image = res.image
			})
		}
	}
}
</script>

<template>
	<div class="page-wrapper white">
		<div class="page-info">
			<div class="detail-title">反馈人信息</div>
			<el-row class="page-row w150">
				<el-col v-for="(item, idx) in list1" :key="idx" :span="item.span" class="item">
					<div class="inner">
						<div class="label">{{ item.label }}</div>
						<div class="content">{{ item.content }}</div>
					</div>
				</el-col>
			</el-row>
			<div class="detail-title">反馈信息</div>
			<el-row class="page-row w150">
				<el-col class="item">
					<div class="inner">
						<div class="label">反馈问题分类</div>
						<div class="content"><span class="detail-type">{{ info.type }}</span></div>
					</div>
				</el-col>
				<el-col class="item">
					<div class="inner">
						<div class="label">反馈时间</div>
						<div class="content">{{ info.created_at }}</div>
					</div>
				</el-col>
				<el-col class="item">
					<div class="inner">
						<div class="label">反馈内容</div>
						<div class="content">{{ info.content }}</div>
					</div>
				</el-col>
				<el-col class="item">
					<div class="inner">
						<div class="label">图片信息</div>
						<div class="content">
							<template v-if="image.length">
								<el-image
										v-for="item in image"
										:preview-src-list="image"
										:src="item"
										fit="cover"
										style="width: 140rem; height: 140rem;margin: 0 20rem 20rem 0">
								</el-image>
							</template>
							<div v-else>无</div>
							<template></template>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="page-tool border">
			<el-button type="primary" @click="$router.back()">返回</el-button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.detail-title {
	border-bottom: 1rem solid #e8e8e8;
	color: #333;
	font-size: 22rem;
	font-weight: bold;
	margin-bottom: 30rem;
	padding: 20rem 0;
}

.detail-type {
	background: #E8EDFF;
	padding: 10rem 20rem;
}
</style>
